@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  /* wyłączenie funkcji pull to refresh na urządzeniach mobilnych. */
  /* overscroll-behavior-y: contain; */
}

* {
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
}

.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: none;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 32, 71);
}

.dropdown-menu {
  min-width: 15rem; 
}

.dropdown-menu-lang {
  min-width: 5rem; 
}

.d-n {
display: none;
  -webkit-transition: display 0.3s ease-in-out;
  transition: display 0.3s ease-in-out;
}



/* //////////////////// A R R O W - A N I M A T I O N ///////////////////////// */

#arrow-down {
  animation: move 5s infinite linear;
}

@keyframes move {
  0% {transform: translateY(0)}
  25% {transform: translateY(15px)}
  50% {transform: translateY(0)}
  75% {transform: translateY(-15px)}
  100% {transform: translateY(0)}
}


/* //////////////////// T O T O P - A R R O W - A N I M A T I O N ///////////////////////// */

#totop-arrow {
  animation: totop 4s infinite linear;
  fill: #67bc45;
}

@keyframes totop {
  0% {transform: translateY(0)}
  25% {transform: translateY(5px)}
  50% {transform: translateY(0)}
  75% {transform: translateY(-5px)}
  100% {transform: translateY(0)}
}


/* //////////////////// NAV-LINKS ///////////////////////// */

@media (min-width: 992px) {
  .nav-link::before {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: #67bc45;
      margin: 0 auto;
      transform: translateY(30px);
      -webkit-transition: width .1s;
      transition: width .1s;
  }

  .nav-link:hover::before {
      width: 90%;
  }
}



/* //////////////////// H E R O -- C A R D S ///////////////////////// */

.card-wrapper {
  overflow: hidden;
}

.card-wrapper > .card-img:hover {
  -o-transform: scale(1.15);
  transform: scale(1.15);
  -webkit-transform: scale(1.15);
}

.card-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transition: all 0.5s ease-in-out;
  z-index: auto;
}

.card-img:after {
  position: absolute;
  content:"";
  height:100%;
  width:100%;
  top:0;
  left:0;
  background: linear-gradient(to top, rgba(66, 66, 66, 0.85) 0%,rgba(255, 255, 255, 0) 60%);
}

.card-img h3, .card-img span {
  z-index: 1;
}

#card-one {
  background-image: url('https://images.unsplash.com/photo-1457530378978-8bac673b8062?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxfDB8MXxhbGx8fHx8fHx8fA&ixlib=rb-1.2.1&q=80&w=1080&utm_source=unsplash_source&utm_medium=referral&utm_campaign=api-credit');   
}
#card-two{
  background-image: url('https://images.unsplash.com/photo-1515150144380-bca9f1650ed9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxfDB8MXxhbGx8fHx8fHx8fA&ixlib=rb-1.2.1&q=80&w=1080&utm_source=unsplash_source&utm_medium=referral&utm_campaign=api-credit');   
}
#card-three{
  background-image: url('https://images.unsplash.com/photo-1546030001-c4e7ba948eda?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYyNjg0OTY2OA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080');
}
#card-four{
  background-image: url('https://images.unsplash.com/photo-1542838132-92c53300491e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxhbGx8fHx8fHx8fHwxNjE4NTk1Njc4&ixlib=rb-1.2.1&q=80&w=1080&utm_source=unsplash_source&utm_medium=referral&utm_campaign=api-credit');
}
#card-five{
  background-image: url('https://images.unsplash.com/photo-1498408040764-ab6eb772a145?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxfDB8MXxhbGx8fHx8fHx8fA&ixlib=rb-1.2.1&q=80&w=1080&utm_source=unsplash_source&utm_medium=referral&utm_campaign=api-credit');
}
#card-six{
  background-image: url('https://images.unsplash.com/photo-1517048676732-d65bc937f952?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MXwxfDB8MXxhbGx8fHx8fHx8fA&ixlib=rb-1.2.1&q=80&w=1080&utm_source=unsplash_source&utm_medium=referral&utm_campaign=api-credit');
}




/* //////////////////// F O O T E R ///////////////////////// */

#footer {
  background: #4b4b4b;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px
}

#footer .footer-top {
  background: #545454;
  border-top: 1px solid #474747;
  border-bottom: 1px solid #474747;
  padding: 30px 0;
}

#footer .footer-top .footer-info {
  margin-top: 30px;
  margin-bottom: 30px
}

#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #545454;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: all 0.2s ease-in-out;
}

#footer .footer-top .social-links a:hover {
  color: #fff;
  text-decoration: none;
  background: #fff;
}

#footer .footer-top .social-links a.instagram:hover {
  color: #eb4eb9;
}

#footer .footer-top .social-links a.facebook:hover {
  color: #5a8ae2;
}

#footer .footer-top .social-links a.youtube:hover {
  color: #f83e41;
}

#footer .footer-top .social-links a.linkedin:hover {
  color: #01a4f0;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px
}

#footer .footer-top .footer-contact {
  margin-top: 30px;
  margin-bottom: 30px
}

#footer .footer-top .footer-contact p {
  line-height: 26px
}

#footer .copyright {
  text-align: center;
  padding-top: 30px
}


@media (max-width: 992px) {
  #footer .footer-top {
      padding-bottom: 1rem;
  }
  
  #footer .footer-top .footer-info {
      margin-top: 10px;
      margin-bottom: 10px
  }

  #footer .footer-top .footer-contact {
      margin-top: 10px;
      margin-bottom: 10px
  }
}